import React, {useContext, useEffect, useState} from 'react';
import Alert from "@material-ui/lab/Alert/Alert";
import {Formik} from "formik";
import {update, getOne, sites} from "./api";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Columns from "../molcules/layout/Columns/Columns";
import {store} from "../store";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {ROLES, TIERS} from "../helpers/constants/roles";

const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 56,
    padding: '0 54px',
    textTransform:'none'
  },
  alert:{
    marginBottom: '30px'
  },
  formSubmit:{
    display:'flex',
    justifyContent:'flex-end'
  }
});

export default function UsersView(){
  const history = useHistory();
  let { id } = useParams();
  const {state} = useContext(store);
  const classes = useStyles();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [user, setUser] = useState([]);
  const [gotData, setGotData] = useState(false);

  useEffect(() => {
    getOne(id).then(res => {
      if (res.data) {
        setUser(res.data);
        setGotData(true);
      }
    });
  }, [id]);

  return (
    <div>
      <div className="heading"><span className="heading-text">User Profile</span></div>
      {submitFailed ? <Alert severity="error" className={classes.alert}>Failed to create a new users.</Alert> : null}
      <Paper className="my-paper">
        {gotData ?
          <div>
          <p className="prompt">Changes here will update a live user</p>
        <div>
          <Formik
            initialValues={{
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role,
              sites: user.sites,
              tier: user.tier,
              bio: user.bio
            }}

            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setSubmitFailed(false);
              update(id, {email: values.email, firstName: values.firstName, lastName: values.lastName, password: values.password, role: values.role, sites: values.sites, bio: values.bio, tier: values.tier}).then(data => {
                setSubmitting(false);
                history.push( "/users");
              }).catch(() => {
                setSubmitFailed(true);
                setSubmitting(false);
              });
            }}

            validationSchema={yup.object().shape({
              email:yup.string().required('Email is required').email(),
              firstName:yup.string().required('First Name is required'),
              lastName:yup.string().required('Last Name is required'),
              role:yup.string().oneOf(Object.values(ROLES)),
              tier:yup.string().oneOf(Object.values(TIERS)),
              sites:yup.string(),bio:yup.string()
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <Columns>
                    <div>
                      <div className="form-group">
                        <TextField
                          id="email"
                          label="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.email ? errors.email : ""}
                          error={touched.email && Boolean(errors.email)}
                          variant="outlined"
                          className="control"
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="firstName"
                          label="First Name"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.firstName ? errors.firstName : ""}
                          error={touched.firstName && Boolean(errors.firstName)}
                          variant="outlined"
                          className="control"
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="lastName"
                          label="Last Name"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.lastName ? errors.lastName : ""}
                          error={touched.lastName && Boolean(errors.lastName)}
                          variant="outlined"
                          className="control"
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          id="bio"
                          label="Bio"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.bio ? errors.bio : ""}
                          error={touched.bio && Boolean(errors.bio)}
                          variant="outlined"
                          className="control"
                          multiline rows="3"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-group">
                        <FormControl variant="outlined" className="control" error={touched.type && Boolean(errors.type)}>
                          <InputLabel id="type-label" >Sites</InputLabel>
                          <Select
                            labelId="sites-label"
                            label="Sites"
                            id="sites"
                            value={values.sites}
                            onChange={(a) => {a.target.id = 'sites'; handleChange(a);}}
                            onBlur={(a) => {a.target.id = 'sites'; handleBlur(a);}}

                          >
                            {sites.map((cat, index) => (
                              <MenuItem value={cat.value} key={index}>{cat.title}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>


                      {state.user.role === 'admin' && <div className="form-group">
                        <FormControl variant="outlined" className="control" error={touched.type && Boolean(errors.type)}>
                          <InputLabel id="type-label" >Role</InputLabel>
                          <Select
                            labelId="role-label"
                            label="Role"
                            id="role"
                            value={values.role}
                            onChange={(a) => {a.target.id = 'role'; handleChange(a);}}
                            onBlur={(a) => {a.target.id = 'role'; handleBlur(a);}}

                          >
                            {Object.values(ROLES).map((role, index) => (
                              <MenuItem value={role} key={index}>{role}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>}


                      {state.user.role === 'admin' && <div className="form-group">
                        <FormControl variant="outlined" className="control" error={touched.type && Boolean(errors.type)}>
                          <InputLabel id="type-label" >Tier</InputLabel>
                          <Select
                            labelId="tier-label"
                            label="Tier"
                            id="tier"
                            value={values.tier}
                            onChange={(a) => {a.target.id = 'tier'; handleChange(a);}}
                            onBlur={(a) => {a.target.id = 'tier'; handleBlur(a);}}
                          >
                            {Object.values(TIERS).map((tier, index) => (
                              <MenuItem value={tier} key={index}>{tier}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>}

                    </div>
                  </Columns>


                  <div className={classes.formSubmit}>
                    <Button
                      disabled={isSubmitting}
                      className={classes.root}
                      type="submit"
                      variant="contained"
                      color="primary">Update
                    </Button>
                  </div>
                </form>);
            }}
          </Formik>
        </div></div> : null}
      </Paper>
    </div>
  );
}
