import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {get, update} from "./api";
import {
  Link, useHistory
} from "react-router-dom";
import Button from "@material-ui/core/Button/Button";
import {useQuery} from "react-query";
import Loading from "../molcules/Loading/Loading";
import MyTable from "../atoms/MyTable";
import AcceptDialog from 'molcules/Dialogs/AcceptDialog';
import { sendInvite } from 'Services/api';

const tableSettings = {
  columns: [
    { title: 'Email', align:'left', field: 'email'},
    { title: 'Name', align:'right', field: 'name'},
    { title: 'Role', align:'right', field: 'role'},
    { title: 'Sites', align:'right', field: 'sites'}
  ],
  actions : [{
    content:(<Button variant={'contained'} size={'small'}>Invite</Button>),
    id: 'invite'
  }]
};

export default function UsersList() {
  const history = useHistory();
  const [onInvite, setOnInvite] = useState(()=>{});
  const [showAcceptInvite, setShowAcceptInvite] = useState(()=>{});
  const {isLoading = true, data, error} = useQuery('users', get);
  let users = data ? data.data : [];
  users = users.map(user => ({...user, name: `${user.firstName} ${user.lastName}`}));

  const rowClick = (event, row) => {
    history.push( `users/${row._id}`);
  };

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <div>
      <AcceptDialog
        show={showAcceptInvite}
        action={'Invite'}
        onAccept={onInvite}
        onClose={() => setShowAcceptInvite(false)}
        heading={'Are you sure you want to send an invite?'}
        message={'This will send an email to the staff member allowing them to register.'}/>

      <div className='heading'><span className="heading-text">Users</span>
        <Link to="/users/create" className="head-action">
          <Button variant="outlined" color="primary" startIcon={<AddCircleOutlineIcon />}>New</Button>
        </Link>
      </div>

      {isLoading && <Loading/>}

      {!!users.length &&
        <Paper className={'paper'}>
          <MyTable
            title={''}
            columns={tableSettings.columns}
            actions={tableSettings.actions}
            data={users}
            initialOrder={{orderBy:'sites', order:'asc'}}
            onRowClick={rowClick}
            onActionClick={(e, actionId, staff) => {
              e.stopPropagation();
              setShowAcceptInvite(true);
              setOnInvite(() => () => {
                sendInvite({email:staff.email, siteName: 'RH'});
                update(staff._id, {inviteStatus:'Invite Sent'});
              });
            }}
          />
        </Paper>
      }

      {!isLoading && users.length === 0 &&
      <p>No users found, <Link to="/users/create">create users</Link>.</p>}

    </div>
  );
}
