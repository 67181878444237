export const ROLES = {
  USER: 'user',
  STAFF: 'staff',
  SITE_ADMIN: 'site-admin',
  SITE_CONSOLE: 'site-console',
  ADMIN: 'admin'
};

export const TIERS = {
  FREE: 'Free',
  MIDDLE: 'Middle',
  PREMIUM: 'Premium'
};
